/* app/ui/destination-finder/destination-finder */

import $ from 'jquery';
import 'selectric';

var $destinationFinder = $('.js-destination-finder');
var $form;

var DestinationFinder = {
	init: function () {
		$form = $destinationFinder.find('form');

		$('.js-destination-finder-select').selectric({
			disableOnMobile: false,
			nativeOnMobile: false,
			onInit: function () {
				$destinationFinder.removeClass('u-hidden');
			}
		});

		DestinationFinder._bindEvent();
	},

	_bindEvent: function () {
		//TBC
		//$form.on('submit', BookingBar._validate);
	}
};

export { DestinationFinder };
