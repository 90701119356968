/* app/ui/social/social */

import $ from 'jquery';
import { Popup } from 'App/popup/popup';

var Social = {
	init: function() {
		Social._updatePosition();
		$('.js-social-popup').on('click', this._processClick);
		$('.js-social-print').on('click', this._printPage);

	},

	_processClick: function( event ) {
		event.preventDefault();
		var $thisLink = $( this );
		var width = $thisLink.attr( 'data-social-width' ) ? $thisLink.attr( 'data-social-width' ) : 550;
		var height = $thisLink.attr('data-social-height') ? $thisLink.attr('data-social-height') : 450;

		Popup.open( this.href, width, height );
	},

	_printPage: function(event) {
		event.preventDefault();
		window.print();
	},

	_updatePosition: function () {
		if (0 < $(".js-site-announcement").length) {
			var announcementHeight = $(".js-site-announcement").outerHeight();
			if (0 < $(".js-share").length) {
				var shareOffsetTop = $(".js-share").offset().top;
				$(".js-share").css({
					"top": (announcementHeight * 0.5) + shareOffsetTop + "px"
				}).top;
			}
		}
	}
};

export { Social };
