/* app/ui/popup/popup-promotion */

import $ from 'jquery';
import * as Core from 'Util/core';
import { publish, subscribe } from 'Util/pubsub';
import Cookies from 'js-cookie';

const selectors = {
	container: '#modal-popup',
	initial: '.js-popup-promo__initial',
	result: '.js-popup-promo__success',
	cta: '.js-popup-promo__cta',
};	
const keys = {
	submitted: 'pp-sb',
	complete: 'pp-cp',
};

let popupDelaySeconds = 5;

let module = {
	init: function () {

		var shouldDisplayPopup = true;
		var $container = $(selectors.container);
		if ($container) {
			
			if (module._getComplete()) {
				shouldDisplayPopup = false;
			}

			if (module._getSubmitted()) {
				module._showSubmissionResult();
				module._setComplete();
				popupDelaySeconds = 0;
			}

			if (shouldDisplayPopup) {
				// timed popup
				window.setTimeout(function () { publish('/modal/open', 'modal-popup'); }, popupDelaySeconds * 1000);
			}

			// init form
			var $form = $container.find('form');
			if ($form) {
				//$form.attr('class', '');
				$form.append("<button class='c-popup__close-btn js-modal__close'>No thanks</button>");
				$form.on('submit', module._submitForm);
				
			} else {
				module._setComplete();
			}

			subscribe('/modal/closed', module._onPopupClosed);
			$(selectors.cta).on('click', module._onCtaClick);
		}
	},

	_onPopupClosed: function ($modal) {
		if ($modal.is(selectors.container) === false) {
			return;
		}
		module._setCookie();
	},

	_onCtaClick: function() {
		module._setCookie();
	},

	_setCookie: function() {
		let $container = $(selectors.container);
		let id = $container.data('popup-id');

		Cookies.set(id, true, { expires: 365, path: '/', secure: true });
	},

	_submitForm: function (event) {
		module._setSubmitted();
	},

	_showSubmissionResult: function() {
		$(selectors.result).show();
		$(selectors.initial).hide();
	},

	_getSubmitted: function() {
		if (window.localStorage) {
			return window.localStorage.getItem(keys.submitted);
		}
		return false;
	},

	_setSubmitted: function () {
		if (window.localStorage) {
			localStorage.setItem(keys.submitted, true);
		}
	},

	_getComplete: function() {
		if (window.localStorage) {
			return window.localStorage.getItem(keys.complete);
		}
		return false;
	},

	_setComplete: function() {
		if (window.localStorage) {
			localStorage.setItem(keys.complete, true);
		}
	}
};

export { module as PopupPromotion };