import $ from 'jquery';
import { Permissions } from 'App/permissions/permissions';

var Cookie = {
	init: function () {
		// If there is 3rd party code that relies on cookies
		// but has not been loaded on the page, prompt the user
		// for permission to use cookies, then load the content if able

		Permissions.get({
			type: 'cookie'
		});
	}
};

export { Cookie };
