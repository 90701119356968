/* util/track */

// Intended for easily tracking events using Google Tag Manager's dataLayer

// This declaration is necessary to tell TypeScript about window.dataLayer
declare global {
	interface Window {
		dataLayer: any[];
	}
}

window.dataLayer = window.dataLayer || [];

/**
 * Event details for Google Analytics Universal,
 * with optional properties for GA4. The GA4 properties
 * should be in snake_case.
 */
interface IEventDetails {
	category: string,
	action: string,
	label: string,

	ga4EventName?: string,
	ga4EventLabel?: string,
}

/**
 * Checks if a given value is an EventDetails.
 */
function _isEventDetails(testVal: unknown): testVal is IEventDetails {
	const val = testVal as IEventDetails;

	if (!val) {
		return false;
	}

	if (!(val.category && typeof val.category === 'string')) {
		return false;
	}

	if (!(val.action && typeof val.action === 'string')) {
		return false;
	}

	if (!(val.label && typeof val.label === 'string')) {
		return false;
	}

	if (val.ga4EventName && typeof val.ga4EventName !== 'string') {
		return false;
	}

	if (val.ga4EventLabel && typeof val.ga4EventLabel !== 'string') {
		return false;
	}

	return true;
}

/**
 * Convert a string to snake_case.
 */
function _toSnakeCase(str: string): string {
	const snakeString = str.toLowerCase().replace(/\s+/g, '_');

	return snakeString;
}

/**
 * Track a GA event.
 */
function trackEvent(eventDetails: IEventDetails): void {
	// Use the same name for each event to minimise setup in GTM
	const event = 'customEvent';

	if (eventDetails?.ga4EventName) {
		eventDetails.ga4EventName = _toSnakeCase(eventDetails.ga4EventName);
	}

	if (eventDetails?.ga4EventLabel) {
		eventDetails.ga4EventLabel = _toSnakeCase(eventDetails.ga4EventLabel);
	}

	if (_isEventDetails(eventDetails)) {
		const eventObj = {
			event,
			eventDetails,
		};

		window.dataLayer.push(eventObj);
	}
}

export {
	IEventDetails,

	trackEvent as event,
};
