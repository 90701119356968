/* app/ui/ajaxreplace/ajaxreplace */

import $ from 'jquery';
import { publish } from 'Util/pubsub';

var selectors = {
	// Classes
	container: '.js-ajax-replace',
	link: '.js-ajax-replace-link',
	select: '.js-mobile-select',
	// Data
	ajaxUrl: 'ajax'
};

var historySupport = window.history && window.history.pushState;
var isAjax = $(selectors.container).length > 0;

var AjaxReplace = {
	init: function () {
		AjaxReplace._initEvents();
	},


	_initEvents: function () {
		if (isAjax) {
			$(document).on('click', selectors.link, AjaxReplace._processLinkClick);
		}

		$(document).on('change', selectors.select, AjaxReplace._filterSelectChange);
		window.onpopstate = AjaxReplace._processPopState;
	},

	_processLinkClick: function (e) {
		var $link = $(this);
		var $container = $link.closest(selectors.container);
		var url = $container.data(selectors.ajaxUrl);
		var query = this.href;

		query = query.match(/(\?[^#]*)($|#)/);
		if (!query) {
			// No query string on ajax link, so treat as a normal link
			return;
		}
		e.preventDefault();

		query = query[1];
		url = url + query;

		$.ajax({
			url: url,
			success: AjaxReplace._processAjaxSuccess($container, query),
			error: AjaxReplace._processAjaxError
		});
	},

	_processAjaxSuccess: function ($container, url) {
		return function (html, status, request) {
			var oldHistoryState;
			var newHistoryState;
			var ajaxUrl;

			$container.replaceWith(html);

			if (historySupport) {
				// History state objects have to be able to be serialised,
				// so the HTML object can't be stored directly. Instead, store
				// the ajax URL and use that to find the same container again


				// This method is build on the assumption that there is only
				// one ajax container with this ajax URL on the page
				ajaxUrl = $container.data(selectors.ajaxUrl);


				$container.find('.selectric-js-filter-dropdown').each(function () {
					var $item = $(this);
					var $select = $item.find('.js-filter-dropdown');
					$item.replaceWith($select);
				});

				oldHistoryState = {
					ajaxUrl: ajaxUrl,
					html: $container.html()
				};

				newHistoryState = {
					ajaxUrl: ajaxUrl,
					html: html
				};

				history.replaceState(oldHistoryState, document.title, document.location.href);
				history.pushState(newHistoryState, document.title, url);
			}

			publish('pagination/ajax/replaced', [$(selectors.container)]);
		};
	},

	_processAjaxError: function (response, status, message) {
		console.error('AJAX load failed:', message);
	},

	_processPopState: function (event) {
		var state = event.state;
		var $container;

		if (state) {
			$container = $(selectors.container + '[data-' + selectors.ajaxUrl + '="' + state.ajaxUrl + '"]');
			$container.html(state.html);
		}
	},


	_filterSelectChange: function () {
		var $select = $(this);
		var selectedValue = $select.val();
		//console.log(value);
		if (isAjax) {
			$select.closest('.js-mobile-select-wrapper').find(selectors.link).filter('[data-value="' + selectedValue + '"]').trigger('click');
		}
		else {
			var url = $select.find('option:selected').attr('href');
			//console.log(url);
			if (url) {
				window.location.href = url;
			}
		}
	}
};

export { AjaxReplace };
