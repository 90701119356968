/* app/ui/listing/filter */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import { publish, subscribe } from 'Util/pubsub';
import 'selectric';

var classes;

var $listingContainer;
var $filter;
var $dropdowns;
var $sort;
var $filterToggle;

var Module = {
	init: function ($elm, classList) {
		$listingContainer = $elm;
		classes = classList;

		Module._initElements($listingContainer);
		Module._initEvents();
		Module._initMediaQueries();
		Module._initSubscriptions();
	},

	_initElements: function ($listingContainer, isHistory) {
		$filter = $listingContainer.find('.js-filter');
		$dropdowns = $filter.find('.js-filter-dropdown');
		$dropdowns.selectric({
			disableOnMobile: false,
			nativeOnMobile: false,
			onInit: function () {
				$filter.removeClass('u-hidden');
			}
		});
		$sort = $filter.find('.js-filter-sort');
		$filterToggle = $listingContainer.find('.js-filter-toggle');
	},

	_initEvents: function () {
		$listingContainer.off('click', '.js-filter-option', Module._removeFilterOption);
		$listingContainer.on('click', '.js-filter-option', Module._removeFilterOption);

		$filter.off('change', '.js-filter-dropdown', Module._applyFilter);
		$filter.on('change', '.js-filter-dropdown', Module._applyFilter);
	},

	_initMediaQueries: function () {

		MediaQueries.register([
			{
				// Bind Small Filter Events
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: Module._initMobileEvents,
				unmatch: Module._unbindMobileEvents
			}, {
				// Bind Large Filter Events
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: Module._initDesktopEvents,
				unmatch: Module._unbindDesktopEvents
			}
		]);
	},

	_initMobileEvents: function () {
		$filterToggle.on('click', Module._toggleFilter);
		$listingContainer.on('click', '.js-filter-cancel', Module._toggleFilter);
		$listingContainer.on('click', '.js-filter-apply', Module._applyMobileFilter);
	},

	_unbindMobileEvents: function () {
		$filterToggle.off('click', Module._toggleFilter);
		$listingContainer.off('click', '.js-filter-cancel', Module._toggleFilter);
		$listingContainer.off('click', '.js-filter-apply', Module._applyMobileFilter);
		$filter.removeClass(classes.open);
	},

	_initDesktopEvents: function () {

	},

	_unbindDesktopEvents: function () {
		$filter.off('change', '.js-filter-dropdown', Module._applyFilter);
	},


	_initSubscriptions: function () {
		subscribe('/filter/rebind', Module._rebindFilter);
	},

	_toggleFilter: function (event) {
		event.preventDefault();
		$('body').toggleClass('filter-open');

		$filter.toggleClass(classes.open);
		if ($filter.hasClass(classes.open)) {
			$filter.off('change', '.js-filter-dropdown', Module._applyFilter);
		}
		else {
			$filter.on('change', '.js-filter-dropdown', Module._applyFilter);
		}
	},

	_applyMobileFilter: function (event) {
		event.preventDefault();

		Module._applyFilter();
		$filter.toggleClass(classes.open);
	},

	_applyFilter: function () {
		var queryObj = Module._getQueryProperties();
		publish('/listing/update', queryObj);
	},

	_removeFilterOption: function (event) {
		event.preventDefault();
		var $option = $(this);
		var filterName = $option.data('filter-name');

		if (filterName !== '') {
			var $dropdown = $('[name="' + filterName + '"]');

			if ($dropdown.length) {
				$dropdown.find('option').eq(0).prop('selected', true);
				$dropdown.trigger('change');
			}
		}
	},

	_getQueryProperties: function () {
		var queryString = '';
		var queryObj = {};
		var filters = [];

		for (var i = 0; i < $dropdowns.length; i++) {
			var $thisDropdown = $dropdowns.eq(i);
			var name = $thisDropdown.attr('name');
			var value = $thisDropdown.val();

			if (value !== '') {
				queryString += '&' + encodeURIComponent(name) + '=' + encodeURIComponent(value);

				filters.push({
					filterName: name,
					filterOption: value
				});
			}
		}

		queryObj.queryString = queryString.substring(1);
		queryObj.filters = filters;

		return queryObj;
	},

	_rebindFilter: function () {
		$listingContainer = $('.js-filter-listing');
		Module._initElements($listingContainer);
		Module._initEvents();
		Module._initMediaQueries();
	}
};

export { Module as Filter };
