/* app/ui/form/form.umbraco */

import $ from 'jquery';

import moment from 'moment';
import Pikaday from 'pikaday';

// Umbraco Forms JS is loaded separately in _Layout.cshtml

import { Track as UmbracoFormTracking } from 'App/track/umbraco-form';

import * as util from 'Util/core';

var selectors = Object.freeze({
	umbracoForm: '.umbraco-forms-form form',
	errorMessage: '.field-validation-error',
	formField: '.umbraco-forms-field',
});

var UmbracoForms = {
	init: function () {
		UmbracoForms.initTracking();

		var $umbracoForms = $('.js-umbraco-form');

		if ($umbracoForms.length) {
			var $datepickers = $umbracoForms.find('.datepickerfield');
			if ($datepickers.length) {
				$datepickers.each(function () {
					var $datepicker = $(this);

					if (window.matchMedia('(pointer: coarse)').matches) {
						$datepicker.attr('readonly', 'readonly');
					}
					new Pikaday({
						field: this,
						yearRange: 10,
						onSelect: function(date) {
							setShadow($datepicker[0].id.replace("_1", ""), date);
						}
					});
				});
			}

			function setShadow(id, date) {
				var value = moment(date).format('YYYY-MM-DD');
				var field = document.getElementById(id);
				field.value = value;
			}

			$(selectors.umbracoForm).on('submit', function (event) {
				var $form = $(event.target);

				// Make asynchronous to let other submit handlers complete
				window.setTimeout(function () {
					var $errorMessages = $form.find( selectors.errorMessage );
					var $firstErrorField;

					if ($errorMessages.length) {
						$firstErrorField = $errorMessages.eq(0).closest(selectors.formField);
						util.scrollToElm( $( $firstErrorField[0] ), 100 );
					}
				}, 0);
			});
		}
	},

	initTracking: function () {
		if (document.querySelector('.js-track__umbraco-form')) {
			UmbracoFormTracking.init();
		}
	},
};

export { UmbracoForms };
