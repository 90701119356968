/* app/util/ajax */

import $ from 'jquery';
import { publish, subscribe } from 'Util/pubsub';

var cache = {};

var Ajax = {
	init: function () {
		subscribe('/ajax/get', $.proxy(this._processRequest, this));
	},

	_processRequest: function (data, param) {
		var callback;
		if (param) {
			callback = this._generateCallback(data.url, data.id, data.callback, param);
			this._getContent(data.url, data.id, callback, param);
		} else {
			callback = this._generateCallback(data.url, data.id, data.callback, '');
			this._getContent(data.url, data.id, callback, '');
		}
	},

	_generateCallback: function (url, id, callback, param) {
		var module = this;
		if (!callback) {
			return function (response) {
				cache[url] = { content: response };
				module._publishResponseEvent(id, response, param);
			};
		} else {
			return function (response) {
				module._publishResponseEvent(id, response, param);
				callback(response);
			};
		}

	},

	_getContent: function (url, id, callback, param) {
		if (cache[url] !== undefined) {
			var response = cache[url].content;
			this._publishResponseEvent(id, response, param);
		} else {
			$.ajax({
				url: url,
				success: callback
			});
		}
	},

	_publishResponseEvent: function (id, response, param) {
		publish('/ajax/ready/' + id, [{ html: response, param: param }]);
	}
};

export { Ajax };
