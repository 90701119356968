/* app/ui/map/map.control */

import $ from 'jquery';
import { subscribe } from 'Util/pubsub';

var selectors = {
	mapcontrol: '.js-map-control'
};

var $maps;
//var maps = [];

var MapControl = {
	init: function($elms) {
		$maps = $elms.filter('[data-map-type="control"]');

		MapControl._initSubscriptions();
	},

	_initSubscriptions: function () {
		subscribe('/map/apiLoaded', MapControl._processMaps);
	},

	_processMaps: function () {

		for (var i = 0; i < $maps.length; i++) {
			var mapCanvas = $maps[i];
			var $thisMap = $maps.eq(i);
			var latitude = $thisMap.attr('data-lat');
			var longitude = $thisMap.attr('data-lng');
			var zoom = parseInt($thisMap.attr('data-zoom'), 10);

			// Sometimes data-lat and data-lng aren't there, but data-latlng is
			var coords = $thisMap.attr('data-latlng');
			coords = coords.split(',');
			coords = coords.map(function (el) {
				return parseFloat(el.trim());
			});

			var latLng;
			if (latitude && longitude) {
				latLng = new google.maps.LatLng(latitude, longitude);
			} else {
				latLng = new google.maps.LatLng(coords[0], coords[1]);
			}

			//var myLocation = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
			//var latLng = new google.maps.LatLng(myLocation.latitude, myLocation.longitude);

			var mapOptions = {
				zoom: zoom || 12,
				center: latLng,
				draggable: !window.matchMedia('(pointer: coarse)').matches,
				streetViewControl: false,
				scrollwheel: false,
				mapTypeControl: true,
				panControl: false,
				panControlOptions: {
					position: google.maps.ControlPosition.LEFT_CENTER
				},
				zoomControl: true,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.LARGE,
					position: google.maps.ControlPosition.RIGHT_BOTTOM
				},
				styles: [
					{
						"featureType": "administrative",
						"elementType": "all",
						"stylers": [
							{
								"saturation": "-100"
							}
						]
					},
					{
						"featureType": "administrative.province",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 65
							},
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": "50"
							},
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "all",
						"stylers": [
							{
								"saturation": "-100"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "all",
						"stylers": [
							{
								"lightness": "30"
							}
						]
					},
					{
						"featureType": "road.local",
						"elementType": "all",
						"stylers": [
							{
								"lightness": "40"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [
							{
								"hue": "#ffff00"
							},
							{
								"lightness": -25
							},
							{
								"saturation": -97
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels",
						"stylers": [
							{
								"lightness": -25
							},
							{
								"saturation": -100
							}
						]
					}
				]
			};

			var map = new google.maps.Map(mapCanvas, mapOptions);

			if ($(selectors.mapcontrol).hasClass('dolphin')) {

				var marker = new google.maps.Marker({
					icon: '/content/images/interface/map/Fuller_Marker.png',
					position: latLng,
					map: map
				});

			} else {

				var marker = new google.maps.Marker({
					icon: '/content/images/interface/map/marker_item.png',
					position: latLng,
					map: map
				});

			}

			//var mapData = {
			//	map: map,
			//	options: mapOptions,
			//	location: myLocation
			//};

			//maps.push(mapData);
		}
	}
};

export { MapControl };
