/* app/ui/book/bookproduct */

import $ from 'jquery';
import { Ajax } from 'Util/ajax';
import { MediaQueries } from 'Util/mediaqueries';
import moment from 'moment';

import 'stickytableheaders';
import { templayed } from 'templayed';
import 'tablescroll';
import { subscribe } from 'Util/pubsub';

var $BookProduct = $('.js-product');
var baseUrl;
var showDateWindow = 1;//mobile 0, tablet vertical 1, tablet landscape 2, destop 3
var groupsize;
var tourType = '-/';
var $DatePicker = $BookProduct.find('.js-booknow-datepicker');

var BookProduct = {
	init: function() {
		Ajax.init();
		BookProduct._initMediaQueries();
		BookProduct._bindEvent();
		//var data= {
		//	url: '/api/product-availibility/WEBSITE/4/2016-12-18/' + showDateWindow,
		//	id: '/api/product-availibility/WEBSITE/4/2016-12-18/' + showDateWindow,
		//	callback: ''
		//}
		//subscribe('/ajax/ready/' + data.id, BookProduct._getAjaxContent);
		//Ajax._processRequest(data);

		BookProduct._bookProductUrlProcess();
	},
	_bindEvent: function() {
		if ($BookProduct.length) {
			$BookProduct.each(function() {
				$BookProduct.on('click', '.js-product-book-btn', BookProduct._bookingChange);
				$BookProduct.on('click', '.js-date-btn', BookProduct._AvailDateArrowClick);
				$BookProduct.on('click', '.js-avail-th-date', BookProduct._AvailDateClick);
				$BookProduct.on('click', '.js-avail-book-btn', BookProduct._AvailTableBookClick);
				$BookProduct.on('change', '.js-booknow-product', BookProduct._bookingChange);
				$BookProduct.on('change', '.js-tour-type', BookProduct._bookingChange);
			});
		}
	},

	_bookProductUrlProcess: function() {
		var checkavail = urlParam('checkavail');
		if (checkavail === 'y') {
			var productId = urlParam('productId');
			var $Product = $('#' + productId);
			$Product.find('.js-product-book-btn').trigger('click');
		}
		var jumpToForm = urlParam('jumptoform');
		if (jumpToForm === 'y') {
			$('body,html').animate({
					scrollTop: $(".c-form__umbraco").offset().top
				},
				1000);
		}

		function urlParam(name) {
			var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
			if (results === null) {
				return null;
			} else {
				return results[1] || 0;
			}
		}
	},

	// As of Version 1.1, core functionality which renders the table is contained in _showAvail.
	// This is to help keep code DRY.
	_bookingChange: function(event) {
		event.stopImmediatePropagation();
		event.preventDefault();

		BookProduct._showAvail(event);
	},

	//header date change
	_AvailDateClick: function(event) {
		event.stopImmediatePropagation();
		event.preventDefault();

		BookProduct._showAvail(event, "header");
	},

	_showAvail: function(event, location) {
		var $Target = $(event.target),
			$ProductRoot = $Target.closest('.js-product'),
			$Product = $ProductRoot.find('.js-booknow-parent'),
			$ProductInfo = $ProductRoot.find('.js-product-info'),
			url,
			date;

		// If the location (optional param) is not header,
		// use default element locations, because we're in the product info area.
		// If we're in the header, we use different elements because the header
		// also holds dates and such.
		if (location !== "header") {
			date = $ProductInfo.find('.js-booknow-datepicker').val();
		} else {
			var headerDate = moment($Target.closest('.js-avail-th-date').data('date'), "YY-MM-DD")
				.format('DD-MM-YYYY');
			$ProductInfo.find('.js-booknow-product').val(headerDate);
		}

		var baseUrl = $Product.data('url'),
			singleDate = $Product.data('single-date'),
			$Button = $Product.find('.js-product-book-btn');
		date = $ProductInfo.find('.js-booknow-product').val();
		groupsize = $ProductInfo.find('.js-booking-guest-num').val();
		var $TourType = $ProductInfo.find('.js-tour-type');
		if ($TourType.length) {
			tourType = $TourType.val();
		} else {
			tourType = '-/';
		}

		// If we've expanded the table, and now clicked "close", close the table and return.
		if ($Target[0] == $Button[0] && $ProductRoot.hasClass('is-expanded')) {
			$ProductRoot.removeClass('is-expanded');
			var bookText = $Target.data('book-text');
			$Target.text(bookText);
			return;
		}
		// If we haven't expanded the table, but we're choosing a date, don't expand the table.
		if ($Target[0] == $ProductInfo.find('.js-booknow-datepicker')[0] &&
			!$ProductRoot.hasClass('is-expanded')) {
			return;
		}

		// So long as date and groupsize are valid, grab the table.
		if (date && groupsize) {
			date = moment(date, "DD-MM-YYYY").format('YYYY-MM-DD');

			url = baseUrl + groupsize + '/' + date + '/' + showDateWindow + '/' + singleDate;
			console.log(url);
			var data = {
				url: url,
				id: url,
				callback: ''
			};
			var params = {
				product: $ProductRoot,
				action: 'booknow'
			};
			console.log(params);
			subscribe('/ajax/ready/' + data.id, BookProduct._getAjaxContent);
			$Button.addClass('c-loading');
			Ajax._processRequest(data, params);
		}
	},


	_getAjaxContent: function(data) {
		console.log(data);
		var $Product = $(data.param.product);
		var action = data.param.action;
		var $AvailTable = $Product.find('.js-product-avail-table');
		var $BookNowBtn = $Product.find('.js-product-book-btn');
		var closeText = $BookNowBtn.data('close-text');
		var bookText = $BookNowBtn.data('book-text');
		//var $AvailTbody = $AvailTable.find('.js-avail-tbody');
		//if (action === 'dateClick') {
		//	//only refresh the tbody and change the header selected status
		//	var $table = $(data.html);
		//	var $newTbody = $table.find('.js-avail-tbody');
		//	if ($newTbody.length) {
		//		$AvailTbody.empty();
		//		$AvailTbody.html($newTbody.html);
		//	}
		//} else {
		$AvailTable.empty();
		$AvailTable.append(data.html);
		//}
		var $productName = $AvailTable.find('.js-td-product-name');
		if ($productName.length) {
			$productName.text($Product.attr('data-product-name'));
		}
		if (action === 'booknow') {
			$BookNowBtn.removeClass('c-loading');
			$BookNowBtn.text(closeText);
		}

		var $TdHeader = $AvailTable.find('.js-avail-th-date');
		if ($TdHeader.length) {
			$TdHeader.removeClass('selected');
			$TdHeader.each(function() {
				var $Header = $(this);
				if ($Header.attr('data-date') === $Header.attr('date-selected-day')) {
					$Header.addClass('selected');
				}
			});
		}
		$AvailTable.removeClass('c-loading');
		$Product.addClass('is-expanded');
		$AvailTable.find('table').stickyTableHeaders();
		$AvailTable.find('table').tableScroll();
	},

	//date arrow click
	_AvailDateArrowClick: function(event) {
		event.stopImmediatePropagation();
		event.preventDefault();
		var $Target = $(event.target);
		var $Product = $Target.closest('.js-product');
		var $TargetTable = $Target.closest('.js-product-avail-table');
		var url = $Target.closest('.js-date-btn').attr('href');
		var data = {
			url: url,
			id: url,
			callback: function() {
				$Product.find('.js-booknow-datepicker')
					.val(moment($('.js-avail-th-date.selected').data('date'), "YY-MM-DD").format('DD-MM-YYYY'));
			}
		};
		var params = {
			product: $Product,
			action: 'dateArrowClick'
		};
		subscribe('/ajax/ready/' + data.id, BookProduct._getAjaxContent);
		$TargetTable.addClass('c-loading');
		console.log(params);
		Ajax._processRequest(data, params);
	},


	//available table 'book' click
	_AvailTableBookClick: function(event) {
		event.stopImmediatePropagation();

		//--------------remove temporally because customlink doesn't support start----------------//
		BookProduct._BookInsertRowClose(event);
		var $Target = $(event.target);
		var $BookTd = $Target.closest('.js-td-show-book');
		var $BookTr = $BookTd.closest('.js-table-row');
		if ($BookTd.length) {
			BookProduct._BookTdInit($Target, $BookTd);
			$BookTd.addClass('selected');
		}
		var insertTemplate = $('#js-booking-insert-row').html();
		var templateVars = BookProduct._insertBookingDataInit($Target);
		if (!$BookTr.length) {
			return;
		}
		var $InsertRow = $BookTr.next('.js-avail-insert-book-row');
		if (!$InsertRow.length) {
			return;
		}
		var template = insertTemplate.replace(/(\r\n|\n|\r)/gm, '');
		var compiledTemplate = templayed(template)(templateVars);
		$InsertRow.html(compiledTemplate);

		$($InsertRow.find('.js-booking-guest-num')[0])
			.val($InsertRow.closest('.js-product').find('.js-booking-guest-num').val());

		BookProduct._insertBookingPriceCalc($InsertRow);


		//bind the calc widget after ajax call return
		var $BookingWidgetCalc = $BookTr.next('.js-avail-insert-book-row').find('.js-booking-widget-calc');
		if ($BookingWidgetCalc.length) {
			$BookingWidgetCalc.each(function() {
				var $item = $(this);
				var $BookingPlus = $item.find('.js-booking-plus');
				var $BookingMinus = $item.find('.js-booking-minus');
				$BookingPlus.on('click',
					function() {
						BookProduct._guestNumUpdate($item, 'plus');
						BookProduct._insertBookingPriceCalc($InsertRow);
					});
				$BookingMinus.on('click',
					function() {
						BookProduct._guestNumUpdate($item, 'minus');
						BookProduct._insertBookingPriceCalc($InsertRow);
					});
			});
		}
		$('.js-avail-insert-book-close').on('click', BookProduct._BookInsertRowClose);
		$('.js-add-to-cart').on('click', BookProduct._JumpToCustomLink);

		//--------------remove temporally because customlink doesn't support end----------------//

		//--------------redirect  start----------------//
		//var $Target = $(event.target);
		//var baseUrl = $Target.attr('data-booking-url');
		//var prodCode = $Target.attr('data-prod-code');
		//var date = $Target.attr('data-date');
		//date = moment(date, "YYYY-MM-DD").format('DD/MM/YYYY');
		//var url = baseUrl + '?tour=' + prodCode + '&DATE=' + date;
		//if (url) {
		//	window.location.href = url;
		//}
		//--------------redirect  stop----------------//
	},

	_BookTdInit: function($href, $BookTd) {
		var item = JSON.parse($href.attr('data-item-info'));
		var fares = item.Fares;
		var $inserDiv = $BookTd.find('.js-td-show-book-detail');
		$inserDiv.empty();
		for (var i = 0; i < fares.length; i++) {
			var fareType;
			var price;
			var showInfo;
			switch (fares[i].Code) {
			case 'ADULT':
				fareType = 'Adult';
				break;
			case 'YOUTH':
				fareType = 'Youth';
				break;
			case 'CHILD':
				fareType = 'Child';
				break;
			case 'CH5-8':
				fareType = 'Child';
				break;
			case 'INFANT':
				fareType = 'Infant';
				break;
			case 'PAS':
				fareType = 'Passengers';
				break;
			case 'CH ADD':
				fareType = 'Additional';
				break;
			default:
				fareType = 'Other';
			}
			price = '$' + fares[i].Price;
			showInfo = fareType + '&nbsp;&nbsp; ' + price;
			$inserDiv.append('<div>' + showInfo + '</div>');
		}
	},

	_insertBookingDataInit: function($href) {
		var templateVars = {};
		var item = JSON.parse($href.attr('data-item-info'));
		var date = $href.attr('data-date');
		var bookUrl = $href.attr('data-booking-url');
		var prodCode = item.Code;
		var reponsiveCss;
		var fares = item.Fares;
		if (fares.length > 2) {
			//var tmplength = fares.length + 1;
			reponsiveCss = 'u-1/4-xl';
		} else {
			reponsiveCss = 'u-1/4-xl';
		}
		for (var i = 0; i < fares.length; i++) {
			fares[i].cssClass = reponsiveCss;
			fares[i].PriceShow = '$' + fares[i].Price;
		}
		templateVars.bookUrl = bookUrl;
		templateVars.prodCode = prodCode;
		templateVars.date = date;
		templateVars.colSpan = showDateWindow * 2 + 1;
		if (showDateWindow === 0) {
			templateVars.colSpan = 3;
		}
		templateVars.Fares = fares;
		templateVars.cssClass = reponsiveCss;
		//console.log(templateVars);
		return templateVars;
	},


	_insertBookingPriceCalc: function($InsertRow) {
		var $PriceBlock = $InsertRow.find('.js-price-block');
		var total = 0;
		if ($PriceBlock.length) {
			$PriceBlock.each(function() {
				var $block = $(this);
				var price = $block.attr('data-price');
				var num = $block.find('.js-booking-guest-num').text();
				var subTotal = price * num;
				total += subTotal;
				$block.find('.js-price-block-subtotal').text('$' + subTotal);
			});

			$InsertRow.find('.js-total').text('$' + total);
		}
	},

	//for booking add and minus widget
	_guestNumUpdate: function($item, action) {
		var $guestNum = $item.closest('.js-booking-widget-calc').find('.js-booking-guest-num');
		//var $plusBtn = $item.find('.js-booking-plus');

		if ($guestNum.length) {
			var currentNum = parseInt($guestNum.text(), 10);
			if (action === 'plus' && currentNum < 25) {
				currentNum++;
			} else if (action === 'minus' && currentNum >= 1) {
				currentNum--;
				//$plusBtn.removeClass('is-disabled');
			}
			//if (currentNum === 25) {
			//	$plusBtn.addClass('is-disabled');
			//}
			$guestNum.text(currentNum);
		}
	},

	_BookInsertRowClose: function(event) {
		event.preventDefault();
		$('.js-avail-insert-book-row').empty();
		$('.js-td-show-book').removeClass('selected');
	},

	_JumpToCustomLink: function(event) {
		event.preventDefault();
		var $target = $(event.target);
		var baseUrl = $target.attr('href');
		var prodCode = $target.attr('data-prod-code');
		var date = $target.attr('data-date');
		var url = baseUrl + '?PAGE=BOOKTOUR';
		var $fares = $target.closest('.js-avail-insert-book-row').find('.js-booking-guest-num');
		var $promoCode = $target.closest('.js-avail-insert-book-row').find('.js-promo-code');
		var farequery = '&FARES=';
		if ($fares.length) {
			$fares.each(function(index, item) {
				var $item = $(item);
				farequery = farequery + $item.attr('data-fare-code') + '|' + $item.text() + '|';
			});
			farequery = farequery.substring(0, farequery.length - 1);
		}
		if (prodCode !== undefined && prodCode !== '') {
			url = url + '&tour=' + prodCode;
		}
		if (date !== undefined && date !== '') {
			url = url + '&date=' + date;
		}
		url = url + farequery + '&ADDTOCART=TRUE';
		if ($promoCode.val() !== '') {
			url = url + '&PROMO=' + $promoCode.val();
		}
		window.location.href = url;
	},


	_initMediaQueries: function() {
		MediaQueries.init();

		var setShowDateWindow = function(bp) {
			return function() {
				if (bp === 'small') {
					showDateWindow = 0;
					$DatePicker.on('click',
						function(event) {
							var $target = $(event.target);
							var scrollHeight = $target.offset().top;
							var top = $("body").scrollTop();
							var bottom = top + $(window).height() - 305;
							if (scrollHeight < top || scrollHeight > bottom) {
								$("html, body").animate({ scrollTop: scrollHeight }, 300);
							}
						});
				} else if (bp === 'medium') {
					showDateWindow = 1;
				} else if (bp === 'large') {
					showDateWindow = 2;
				} else if (bp === 'extra-large') {
					showDateWindow = 3;
				}
			};

		};

		MediaQueries.register([
			{
				queries: MediaQueries.queries['booking--extra-large'],
				shouldDegrade: false,
				match: setShowDateWindow('extra-large')
			},
			{
				queries: MediaQueries.queries['booking--large'],
				shouldDegrade: false,
				match: setShowDateWindow('large')
			},
			{
				queries: MediaQueries.queries['booking--medium'],
				shouldDegrade: false,
				match: setShowDateWindow('medium')
			},
			{
				queries: MediaQueries.queries['booking--small'],
				shouldDegrade: false,
				match: setShowDateWindow('small')
			}
		]);
	}
};

export { BookProduct };
