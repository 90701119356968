/* app/ui/track/umbraco-form */

import { listen } from 'Util/core';
import * as track from 'Util/track';

const selectors = Object.freeze({
	formWrapper: '.js-track__umbraco-form',
	form: `.js-track__umbraco-form form`,
	errorField: '.input-validation-error',
} as const);

const dataAttributes = Object.freeze({
	formName: 'data-track-form-name',
} as const);

/**
 * The duration (in milliseconds) to listen for successful form submissions following the 'submit' event.
 */
const listenDuration = 10;

/**
 * The Umbraco Form that is being checked for submission.
 */
let $currentForm: HTMLFormElement | null = null;

/**
 * Whether or not the current submission being listened to has been detected as being successful.
 */
let submissionDetected = false;

/**
 * Initialise the Umbraco Form tracking module.
 */
function init() {
	_initEvents();
}

/**
 * Bind event listeners for the Umbraco Form tracking module.
 */
function _initEvents() {
	listen(selectors.form, 'submit', _submitEvent);
}

/**
 * Callback for the form submit event.
 */
function _submitEvent(e: SubmitEvent) {
	// This is called before Umbraco Forms' submit handler, so errors are not yet shown. There are three potential outcomes from this:
	// A. The validation fails, preventing the default submit action and marking forms as being in an error state.
	// B. The validation succeeds, allowing the default submit action to take place and reloading the page.
	// C. The valiation succeeds, but the default submit action is still prevented so the form can be replaced with a message without reloading the page.

	/** The form currently being submitted */
	const $form = e.target;

	if ($form && $form instanceof HTMLFormElement && $form.matches(selectors.form)) {
		submissionDetected = false;
		_rememberCurrentForm($form);

		// A. The validation fails
		// Because we don't need to track validation failing, we can ignore this state.

		// B. The default action occurs
		// This will trigger an unload event immediately, so we can detect it. If it doesn't happen immediately, stop listening
		_listenForDefaultFormAction();

		// C. The form it replaced without a page reload
		_listenForFormReplacement();
	}
}

function _rememberCurrentForm($form: HTMLFormElement) {
	$currentForm = $form;
	window.setTimeout(() => { $currentForm = null }, listenDuration);
}

/**
 * Set up a listener for the 'unload' event to see if the default form submission causes a page reload. Only listen for listenDuration.
 */
function _listenForDefaultFormAction() {
	window.addEventListener('beforeunload', _formSubmitted);

	window.setTimeout(() => { window.removeEventListener('beforeunload', _formSubmitted) }, listenDuration);
}

/**
 * After the main thread is complete, check if the form is in an error state. If not, assume it has been successfully submitted.
 */
function _listenForFormReplacement() {
	window.setTimeout(() => {
		const errorState = _isFormInErrorState();

		if (errorState === false) {
			// The form is not in an error state, so it was submitted successfully
			_formSubmitted();
		}
	}, 0);
}

/**
 * Checks if a given Umbraco Form is in an error state.
 */
function _isFormInErrorState(): boolean {
	let errorState = false;

	if ($currentForm?.querySelector(selectors.errorField)) {
		errorState = true;
	}

	return errorState;
}

/**
 * Get the name of a given Umbraco Form.
 */
function _getFormName($form = $currentForm): string | undefined {
	const $wrapper = $form?.closest(selectors.formWrapper);
	if ($wrapper) {
		const formName = $wrapper.getAttribute(dataAttributes.formName);
		if (formName) {
			return formName;
		}
	}
}

/**
 * Track a form submission.
 */
function _formSubmitted() {
	if (submissionDetected === false) {
		submissionDetected = true;

		const formName = _getFormName();
		if (formName) {
			const eventObj: track.IEventDetails = {
				category: 'Form',
				action: 'Submit',
				label: formName,

				ga4EventLabel: formName,
				ga4EventName: 'umbraco_form_submit',
			};

			track.event(eventObj);
		}
	}
}

const Track = {
	init
};

export { Track };
