/* app/ui/nav/large */

import $ from 'jquery';
import * as Core from 'Util/core';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';
import 'hoverIntent';

var NavLarge;
var $nav;
var selectors;
var classes;

var navHeight;
var navPos;
var topPos;

var $window;
var $body;
var $anchorLinks;
var $navWithMenus;

const noTouch = matchMedia('(pointer: fine)').matches;

var NavLarge = {
	init: function($elm, selectorList, classList) {
		NavLarge = this;
		$nav = $elm;
		selectors = selectorList;
		classes = classList;

		NavLarge._initElements();
		NavLarge._setSizes();
		NavLarge._initSubscriptions();
		NavLarge._initEvents();
	},

	_setSizes: function() {
		navHeight = $nav.outerHeight();
		navPos = $nav.offset().top;
	},

	_initElements: function () {
		$window = $(window);
		$body = $('body');
		$navWithMenus = $nav.find(selectors.navItemMega);
		$anchorLinks = $('.js-anchor-link');
	},

	// Listen for window resize event (listener bound on all.js)
	_initSubscriptions: function () {
		//subscribe('/window/resize', NavLarge._setSizes);
		//subscribe('/window/scroll', NavLarge._stickyNav);
	},

	_initEvents: function() {
		// Using hover if "touch" device
		if (noTouch) {
			$navWithMenus.hoverIntent({
				over: NavLarge._toggleMegaMenuHover,
				out: NavLarge._toggleMegaMenuHover,
				interval: 30
			});
		}

		$anchorLinks.on('click', NavLarge._processAnchorClick);

		if (noTouch) {
			return;
		}
		this._bindTouchEvents();
	},

	_toggleMegaMenu: function(target, click, event) {
		if (click === 'click') {
			event.preventDefault();
		}
		var $thisTarget = $(target);
		var $thisNav = $thisTarget.is(selectors.navItemMega) ? $thisTarget : $thisTarget.closest(selectors.navItemMega);
		var $expanded = $nav.find('.' + classes.expanded);

		if (click && click === 'click' && $expanded.find(selectors.megamenuHeading)[0] !== target) {
			$expanded.removeClass(classes.expanded);
		}

		$thisNav.toggleClass(classes.expanded);
		$('.js-header').toggleClass('menu-expanded');
	},

	unbind: function () {
		$anchorLinks.off('click', NavLarge._processAnchorClick);
		$navWithMenus.unbind('click', this._toggleMegaMenu);
		$navWithMenus.unbind('mouseenter').unbind('mouseleave');
		$navWithMenus.removeProp('hoverIntent_t');
		$navWithMenus.removeProp('hoverIntent_s');
		unsubscribe('/window/scroll', NavLarge._stickyNav);
	},

	_toggleMegaMenuHover: function() {
		publish('/nav/navToggle');
		NavLarge._toggleMegaMenu(this);
	},

	_toggleMegaMenuClick: function(event) {
		publish('/nav/navToggle');
		NavLarge._toggleMegaMenu(this, 'click', event);
	},

	_processAnchorClick: function(event) {
		event.preventDefault();

		var hash = this.href.split('#')[1];
		var $anchor = $('#' + hash);

		if ($anchor.length) {
			Core.scrollToElm($anchor, navHeight -1);
		}
	},

	_bindTouchEvents: function() {
		$navWithMenus.each(function() {
			$(this).find(selectors.megamenuHeading).on('click', NavLarge._toggleMegaMenuClick);
		});
		$('body').on('click', NavLarge._handleBodyClick);
	},

	_handleBodyClick: function(e) {
		var $target = $(e.target);

		if (!$target.closest(selectors.nav).length && $nav.find('.' + classes.expanded).length) {
			$nav.find('.' + classes.expanded).removeClass(classes.expanded);
		}
	},

	_stickyNav: function() {
		var newTopPos = $window.scrollTop();

		if (newTopPos > navPos) {
			$nav.addClass(classes.sticky);
			//$body.css('padding-top', navHeight);
		} else {
			$nav.removeClass(classes.sticky);
			//$body.css('padding-top', 0);
		}
		topPos = newTopPos;
	}
};

export { NavLarge };
