/* app/ui/listing/filter-listing */

import $ from 'jquery';
import * as Core from 'Util/core';
import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace';
import { Filter } from 'App/listing/filter';
import 'mixItUp';
import { publish, subscribe } from 'Util/pubsub';

var selectors = {
	listingContainer: '.js-filter-listing',
	// Data
	ajaxUrl: 'ajax'
};
var classes = {
	open: 'is-open'
};

var historySupport = window.history && window.history.pushState;

var fromHistory = false;

var $listingContainer;
var baseUrl;
var queryObj = {};

var FilterListing = {
	init: function () {
		$listingContainer = $(selectors.listingContainer);
		baseUrl = $listingContainer.data(selectors.ajaxUrl);
		//AjaxReplace.init();
		FilterListing._initMixItUp($listingContainer);
		FilterListing._initEvents();
		FilterListing._initSubscriptions();
	},

	_rebindEvent: function () {
		//Filter.init($(selectors.listingContainer), classes);
	},

	_initEvents: function () {
		window.onpopstate = FilterListing._processPopState;
		Filter.init($listingContainer, classes);
	},

	_initSubscriptions: function () {
		subscribe('/listing/update', FilterListing._initListing);
		subscribe('pagination/ajax/replaced', FilterListing._paginationDone);
	},


	// Update listing when new filters are applied
	_initListing: function (queryData) {
		queryObj = queryData;
		fromHistory = false;
		var url = baseUrl + '?' + queryObj.queryString;
		FilterListing._fetchFeedData(url);
	},

	// Fetch feed data from AjaxController
	_fetchFeedData: function (url) {

		$.ajax({
			url: url,
			success: FilterListing._fetchFeedDataComplete(url),
			error: FilterListing._fetchFeedDataError
		});
	},

	_fetchFeedDataComplete: function (url) {
		return function(data) {
			var oldHistoryState;
			var newHistoryState;
			var $listingContainer = $(selectors.listingContainer);
			var ajaxUrl;

			$listingContainer.replaceWith(data);


			if (historySupport) {

				ajaxUrl = $listingContainer.data(selectors.ajaxUrl);

				//customized to destroy the selectric
				$listingContainer.find('.selectric-js-filter-dropdown').each(function() {
					var $item = $(this);
					var $select = $item.find('.js-filter-dropdown');
					$item.replaceWith($select);
				});

				oldHistoryState = {
					ajaxUrl: ajaxUrl,
					html: $listingContainer.html()
				};

				newHistoryState = {
					ajaxUrl: ajaxUrl,
					html: data
				};

				history.replaceState(oldHistoryState, document.title, document.location.href);
				history.pushState(newHistoryState, document.title, '?' + queryObj.queryString);

			}


			$listingContainer = $(selectors.listingContainer); // dom replaced, so re-define it

			FilterListing._processHtml($listingContainer);
		};
	},

	_fetchFeedDataError: function (data) {
		console.log('Ah crap it, data did not load');
	},

	_paginationDone: function ($listingContainer) {
		FilterListing._processHtml($listingContainer);
		Core.scrollToElm($listingContainer);
	},

	_processHtml: function ($listingContainer) {
		FilterListing._initMixItUp($listingContainer);
		publish('/lazyload/image', [$listingContainer.find('img')]); //for stacklist pagination
		publish('/filter/rebind');
	},

	_processPopState: function (event) {
		var state = event.state;
		var $container;

		if (state) {
			$container = $(selectors.listingContainer + '[data-' + selectors.ajaxUrl + '="' + state.ajaxUrl + '"]');
			$container.html(state.html);

			FilterListing._processHtml($listingContainer);
		}
	},

	_initMixItUp: function ($listingContainer) {
		var $productPods = $listingContainer.find('.js-tiled-pods');
		$productPods.mixItUp({
			animation: {
				duration: 500,
				effects: 'fade scale stagger',
				perspectiveOrigin: '0 0'
			},
			selectors: {
				target: '.js-product-pod-wrapper'
			},
			callbacks: {
				onMixStart: function () {

					$(this).find('.js-lazy-bg').each(function () {
						var $thisImg = $(this);
						var bg = 'url(\'' + $thisImg.attr('data-original') + '\')';
						$thisImg.css('background-image', bg);
					});

				}
			}
		});
	}

};

export { FilterListing };
