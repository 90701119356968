/* app/ui/rotator/rotator */

import $ from 'jquery';
import Flickity from 'flickity';

var $rotators;

var selectors = {
	rotator: '.js-rotator-flickity',
};

var dataSelectors = {
	flickity: 'flickity-instance'
};

var module = {
	init: function ($elms) {
		module._initElements($elms);
		module._initRotators();
	},

	_initElements: function ($elms) {
		$rotators = $elms;
	},

	_initRotators: function () {
		var i;
		var $rotator;

		for (i = 0; i < $rotators.length; i++) {
			$rotator = $rotators.eq(i);
			module._initRotator($rotator);
		}
	},

	_initRotator: function ($rotator) {
		var rotatorType = $rotator.attr('data-rotator-type') || 'content';
		var rotatorNav = $rotator.attr('data-rotator-nav') || '';
		var settings = module._getRotatorSettings($rotator, rotatorType, rotatorNav);
		var thisFlick = new Flickity($rotator[0], settings);

		$rotator.data(dataSelectors.flickity, thisFlick);
	},

	// Return the settings for the type of rotator that has been initialised
	// These can be edited, and new types can be added
	// Documentation for the options can be found here: https://flickity.metafizzy.co/
	_getRotatorSettings: function ($rotator, rotatorType, rotatorNav) {
		var settings;
		var arrowShape = {
			x0: 10,
			x1: 60, y1: 50,
			x2: 65, y2: 45,
			x3: 20
		};

		switch (rotatorType) {

			case 'gallery':
				settings = {
					arrowShape: arrowShape,
					wrapAround: true,
					lazyLoad: 1,
					pageDots: false
				};
				break;

			default:
				settings = {
					prevNextButtons: false,
					autoPlay: false,
					wrapAround: false,
					lazyLoad: true,
				};
		}
		return settings;
	},
};

export { module as RotatorFlickity };
