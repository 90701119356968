/* app/ui/rotator/rotator */

import $ from 'jquery';
import slick from 'slick-carousel';

var $rotators;

var Rotator = {
	init: function ($elms) {
		$rotators = $elms;

		for (var i = 0; i < $rotators.length; i++) {
			var $thisRotator = $rotators.eq(i);
			var rotatorType = $thisRotator.attr('data-rotator-type') || 'content';
			var settings = Rotator._getRotatorSettings($thisRotator, rotatorType);

			$thisRotator.slick(settings);

			$thisRotator.on('init', Rotator._onInit);

			if (rotatorType === 'carousel' || rotatorType === 'dual-nav') {
				$thisRotator.on('beforeChange', Rotator._onBeforeChangeMultiple);
			} else {
				$thisRotator.on('beforeChange', Rotator._onBeforeChange);
			}
		}
	},

	_onInit: function(slick) {
		var $visibleSlides = slick.$slides.filter(':visible');

		$visibleSlides.each(function() {
			var $slide = $(this);
			if ($slide.find('img[data-original]').length && !Rotator._isImageLoaded($slide)) {
				Rotator._processImg($slide);
			}
		});
	},

	// Runs before the next slide is shown
	// Check if image source is correct, if not - lazyload it
	_onBeforeChange: function (event, slick, currentSlide, nextSlide) {
		var $nextSlide = slick.$slides.eq(nextSlide);

		//if it's a lazy bg rotator
		if ($nextSlide.find('.js-lazy-bg').length) {
			for (var i = 0; i < 2; i++) {
				var $thisSlide = slick.$slides.eq(nextSlide + i);
				Rotator._processBg($thisSlide);

			}
		}

		if ($nextSlide.find('img[data-original]').length && !Rotator._isImageLoaded($nextSlide)) {
			Rotator._processImg($nextSlide);
		}
	},

	_onBeforeChangeMultiple: function (event, slick, currentSlide, nextSlide) {
		// todo: the number i defines the images to show.
		for (var i = 0; i < 3; i++) {
			var $nextSlide = slick.$slides.eq(nextSlide + i);
			if ($nextSlide.find('img[data-original]').length && !Rotator._isImageLoaded($nextSlide)) {
				Rotator._processImg($nextSlide);
			}
		}
	},

	// Process image lazyloading
	// Get the actual image source and overwrite the 'src' attribute
	_processImg: function ($nextSlide) {
		var $nextImg = $nextSlide.find('img');
		// in most case, rotator use different crop in different screensize, so copy all the crop imgs
		$.each($nextImg, function (index, item) {
			var nextImgSrc = $(item).data('original');
			$(item)[0].src = nextImgSrc;
		});
	},

	// Return true if the image 'src' attribute matches the 'data-original' attribute
	_isImageLoaded: function ($nextSlide) {
		var $nextImage = $nextSlide.find('img');
		return $nextImage[0].src.indexOf($nextImage.data('original')) !== -1;
	},

	_processBg: function ($nextSlide) {
		$nextSlide.find('.js-lazy-bg').each(function () {
			var $thisImg = $(this);
			var bg = 'url(\'' + $thisImg.attr('data-original') + '\')';
			$thisImg.css('background-image', bg);
		});

	},

	// Return the settings for the type of rotator that has been initialised
	// These can be edited, and new types can be added
	// Documentation for the options can be found here: http://kenwheeler.github.io/slick/
	_getRotatorSettings: function ($rotator, rotatorType) {
		var settings;

		switch (rotatorType) {

			case 'image':
				settings = {
					infinite: true,
					dots: true,
					autoplay:true
				};
				break;
			case 'experience':
				settings = {
					infinite: false,
					dots: false,
					autoplay: false,
					arrows: true,
					slidesToShow: 2,
					slidesToScroll: 2,
					responsive: [
						{
							breakpoint: 1023,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				};
				break;
			case 'carousel':
				settings = {
					infinite: false,
					slidesToShow: 3,
					responsive: [
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 2
							}
						},
						{
							breakpoint: 479,
							settings: {
								slidesToShow: 1
							}
						}
					]
				};
				break;

			case 'dual':
				var navId = $rotator.attr('data-carousel-dual');
				settings = {
					infinite: false,
					slidesToShow: 1,
					fade: true,
					asNavFor: '[data-carousel-dual-nav="' + navId + '"]'
				};
				break;

			case 'dual-nav':
				var mainId = $rotator.attr('data-carousel-dual-nav');
				settings = {
					infinite: false,
					slidesToShow: 3,
					slidesToScroll: 3,
					asNavFor: '[data-carousel-dual="' + mainId + '"]',
					focusOnSelect: true,
					responsive: [
						{
							breakpoint: 767,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 2
							}
						},
						{
							breakpoint: 479,
							settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				};
				break;

			case 'hero':
				settings = {
					dots: false,
					infinite: true,
					slidesToShow: 1,

					responsive: [
						{
							breakpoint: 1023,
							settings: {
								arrows: false,
								dots: true
							}
						}
					]
				};
				break;

			default:
				settings = {

				};
		}
		return settings;
	}
};

export { Rotator };
