/* app/ui/nav/load */

import $ from 'jquery';
import Velocity from 'velocity-animate';

import { MediaQueries } from 'Util/mediaqueries';
import * as Keybinding from 'Util/keybinding';
import { NavSmall } from 'App/nav/small';
import { NavLarge } from 'App/nav/large';

import { publish, subscribe } from 'Util/pubsub';

var selectors = {
	nav: '.js-navigation',
	header: '.js-header',
	navItem: '.js-navigation__item',
	navIcon: '.js-navigation__icon',
	navItemMega: '.js-navigation__item--megamenu',
	megamenuHeading: '.js-megamenu__heading',
	noTouch: '.no-touchevents'
};
var classes = {
	expanded: 'is-expanded',
	collapsed: 'is-collapsed',
	sticky: 'is-sticky'
};

var $nav;
var $search;
var $searchToggle;
var $langToggle;
var $lang;
var $booknow;
var $booknowToggle;
var $window;
var $body;
var $header;
var headerHeight;
var headerPos;
var topPos;

var NavLoad = {
	init: function() {
		NavLoad._initElements();

		if ($nav.length) {
			NavLoad._initSubscriptions();
			NavLoad._setSizes();
			NavLoad._initEvents();
			NavLoad._initMediaQueries();
		}
	},

	_initElements: function() {
		$nav = $(selectors.nav);
		$searchToggle = $('.js-header__search-toggle');
		$search = $('.js-header__search');
		$langToggle = $('.js-header__lang-toggle');
		$lang = $('.js-header__lang');

		$header = $(selectors.header);
		$window = $(window);
		$body = $('body');
		$booknow = $('.js-header__booknow');
		$booknowToggle = $('.js-header__booknow-toggle');
	},

	_initSubscriptions: function () {
		subscribe('/nav/navToggle', NavLoad._closeSearch);
		subscribe('/nav/langToggle', NavLoad._closeSearch);
		subscribe('/nav/navToggle', NavLoad._closeLang);
		subscribe('/nav/searchToggle', NavLoad._closeLang);
		subscribe('/window/scroll', NavLoad._stickyHeader);
	},

	_initEvents: function() {
		$searchToggle.on('click', NavLoad._toggleSearch);
		$langToggle.on('click', NavLoad._toggleLang);
		$booknowToggle.on('click', NavLoad._toggleBooknow);
		Keybinding.bindKey('?', NavLoad._focusSearch);
	},

	// publish search toggle event
	// show/hide site search dependant on current state
	// toggle class on search button
	_toggleSearch: function (event) {
		event.preventDefault();
		publish('/nav/searchToggle');
		$search.toggleClass(classes.expanded);
		$searchToggle.toggleClass(classes.expanded);

		if ($search.hasClass(classes.expanded)) {
			$('.js-header').addClass('menu-expanded');
			setTimeout(function () {
				$search.find('.js-header__search-input').focus();
			}, 800);
		}
		else {
			$('.js-header').removeClass('menu-expanded');
		}
	},

	// focus on the search element
	_focusSearch: function (event) {
		$search.find('.js-header__search-input').focus();
	},

	// close site search if open
	_closeSearch: function() {
		if ($searchToggle.hasClass(classes.expanded)) {
			$searchToggle.trigger('click');
		}
	},

	// publish language toggle event
	// show/hide site search dependant on current state
	// toggle class on language button
	_toggleLang: function (event) {
		event.preventDefault();
		publish('/nav/langToggle');
		$lang.toggleClass(classes.expanded);
		$langToggle.toggleClass(classes.expanded);
	},

	// close language picker if open
	_closeLang: function () {
		if ($langToggle.hasClass(classes.expanded)) {
			$langToggle.trigger('click');
		}
	},

	_toggleBooknow: function (event) {
		event.preventDefault();
		publish('/nav/booknowToggle');
		$booknow.toggleClass(classes.expanded);
		$booknowToggle.toggleClass(classes.expanded);
	},

	_closeBooknow: function () {
		if ($booknowToggle.hasClass(classes.expanded)) {
			$booknowToggle.trigger('click');
		}
	},

	_initMediaQueries: function() {

		MediaQueries.register([
			{
				// Bind Small Nav
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: function() {
					NavSmall.init($nav, selectors, classes);
				},
				unmatch: function() {
					NavSmall.unbind($nav, selectors, classes);
				}
			}, {
				// Bind Large Nav
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: function() {
					NavLarge.init($nav, selectors, classes);
				},
				unmatch: function() {
					NavLarge.unbind($nav, selectors, classes);
				}
			}
		]);
	},

	_setSizes: function () {
		headerHeight = $header.outerHeight();
		headerPos = $header.offset().top;
	},


	_stickyHeader: function () {
		var newTopPos = $window.scrollTop();

		if (newTopPos > 20) {
			$header.addClass(classes.sticky);
			//$body.css('padding-top', headerHeight);
		} else {
			$header.removeClass(classes.sticky);
			//$body.css('padding-top', 0);
		}
		topPos = newTopPos;
	}
};

export { NavLoad };