/* util/core */

import $ from 'jquery';

var scrollTimeout = null;
var scrollSpeedMult = 2;
var scrollSpeedMax = 600;

var module = {
	// Scroll to an element on screen
	// $elm = jQuery element - the element to scroll to
	// pxAbove = int - padding between top of screen and element (defaults to 0)
	scrollToElm: function( $elm, pxAbove ) {
		var topPadding = pxAbove || 0;
		var scrollHeight = $elm.offset().top;
		$( 'html, body' ).animate( { scrollTop: scrollHeight - topPadding }, 'slow' );
	},

	// Returns a class name with no '.'
	// selector = string - the jQuery selector to remove '.' from
	getClassName: function( selector ) {
		return selector.replace( '.', '' );
	},

	// Returns an object containing the current URL's query string
	// Combines values with the same key
	// e.g. 'filter-content-type=photos,videos' instead of form.serialize which will return 'filter-content-type=photos&filter-content-type=videos'
	getQueryStringObject: function (queryString) {
		queryString = typeof queryString !== 'undefined' ? queryString : document.location.search.substr(1);
		queryString = queryString.split('&');

		var query = {};

		for (var i = 0; i < queryString.length; i++) {
			queryString[i] = queryString[i].split('=');

			if (queryString[i].length > 1) {
				var key = decodeURIComponent(queryString[i][0]);
				var val = decodeURIComponent(queryString[i][1]);

				if (query[key]) {
					query[key] += ',' + val;
				} else {
					query[key] = val;
				}
			}
		}

		return query;
	},

	// Creates a callback that, when bound to a keydown event,
	// will only trigger on that event when the "Enter" key is pressed.
	// Any other bound events (e.g. 'click' above) will always be triggered.

	// Example usage:
	// e.g. $(document).on('click keydown', selector, activate(callback));
	activate: function ( callback ) {
		return function(e) {
			if (e.type !== 'keydown' || e.which === 13) {
				// Enter key (keycode 13)
				callback.apply(this, arguments);
			}
		};
	}
};

var scrollToElm = module.scrollToElm;
var getClassName = module.getClassName;
var getQueryStringObject = module.getQueryStringObject;
var activate = module.activate;

/**
 * listen - Bind an event listener to one or more elements
 *
 * @param  {string} selector - The selector for elements that need the event bound
 * @param  {string} event - The event to listen for
 * @param  {(Event) => void} callback - The function to call when the event happens
 *
 * @return {void}
 */
const listen = (selector, event, callback) => {
	document.querySelectorAll(selector).forEach(($el) => $el.addEventListener(event, callback));
};

/**
 * unlisten - Remove an event listener from one or more elements
 *
 * @param  {string} selector - The selector for elements that need the event unbound
 * @param  {string} event - The event to stop listening for
 * @param  {(Event) => void} callback - The function to unbind from the event
 *
 * @return {[type]} [description]
 */
const unlisten = (selector, event, callback) => {
	document.querySelectorAll(selector).forEach(($el) => $el.removeEventListener(event, callback));
};

export {
	scrollToElm,
	getClassName,
	getQueryStringObject,
	activate,

	listen,
	unlisten,
};
