import $ from 'jquery';

import { LazyLoad } from 'Util/lazyload';
import { VideoLoad } from 'App/video/video.load';
import { NavLoad } from 'App/nav/load';
import { TrackLoad } from 'App/track/load';

import moment from 'moment';
import Pikaday from 'pikaday';

import { Rotator } from 'App/rotator/rotator';
import { RotatorFlickity } from 'App/rotator/rotator-flickity';

import { ExpandCollapse } from 'App/expand-collapse/expand-collapse';

import { VideoPlayer } from 'App/video/video.player';

import { Social as SocialShare } from 'App/social/share';

import { MapLoad } from 'App/map/map.load';

import { UmbracoForms } from 'App/form/form.umbraco';
import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace';

import { Cookie } from 'App/cookie/cookie';
import { BackToTop } from 'App/back-to-top/back-to-top';
import { BookNow } from 'App/book/booknow';
import { FilterListing } from 'App/listing/filter-listing';

import { modal } from 'App/modal/modal';
import { PopupPromotion } from 'App/popup/popup-promotion';

import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

import 'selectric';

TrackLoad.init();
NavLoad.init();
LazyLoad.init();
VideoLoad.init();
AjaxReplace.init();
SocialShare.init();
BookNow.init();
Cookie.init();
modal.init();
PopupPromotion.init();

// Filtered Listing
var $filterListing = $('.js-filter-listing');
if ($filterListing.length) {
	FilterListing.init();
}

// Date Picker
var $DatePicker = $('.js-booknow-datepicker');
if ($DatePicker.length) {
	$DatePicker.each(function () {
		var datepicker = new Pikaday(
			{
				field: $(this)[0],
				firstDay: 1,
				minDate: new Date(),
				maxDate: new Date('31-12-2025'),
				yearRange: [2000, 2025],
				container: $(this).parent()[0],
				format: 'DD-MM-YYYY',
				onSelect: function () {
					var date = moment(datepicker.toString(), 'DD-MM-YYYY').format('DD-MM-YYYY');
					$(this).attr('data-date', date);
					$(this).val(moment(datepicker.toString(), 'DD-MM-YYYY').format('DD-MM-YYYY'));
				}
			});
	});
}

// Expand/Collapse
ExpandCollapse.init();

// Video Macros
VideoPlayer.init();

// Umbraco Forms
// init function will load required dependencies if an umbraco form is present
UmbracoForms.init();

// Maps
var $maps = $('.js-map');
if ($maps.length) {
	MapLoad.init($maps);
}

// Rotators
var $rotators = $('.js-rotator');
if ($rotators.length) {
	Rotator.init($rotators);
}
var $flickityRotators = $('.js-rotator-flickity');
if ($flickityRotators.length) {
	RotatorFlickity.init($flickityRotators);
}

// Back to top
if ($('.js-back-to-top').length) {
	BackToTop.init();
}

// Publish resize and scroll events for other modules to use
const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));
publish('/window/scroll');

//language dropdown, check http://selectric.js.org/
$('.js-header-language').selectric({
	disableOnMobile: false,
	nativeOnMobile: false,
	onInit: function () {
		$('.js-header-language-wrapper').addClass('is-loaded');
	},
	onBeforeOpen: function () {
		$('.js-header').addClass('lang-expanded');
	},
	onBeforeClose: function () {
		$('.js-header').removeClass('lang-expanded');
	},
	onChange: function () {
		window.location.href = this.value;
	}
});

//////////////
// Homepage //
//////////////

import { DestinationFinder } from 'App/destination-finder/destination-finder';

DestinationFinder.init();


/////////////////
// Detail page //
/////////////////

import { BookProduct } from 'App/book/bookproduct';
import 'tablescroll';
import 'magnific-popup';

//for product booking
BookProduct.init();

// Check DOM for elements requiring JS
var $tables = $('.c-rte').find('table');

// Table Scroll
if ($tables.length) {
	$tables.tableScroll();
}

// Single and Multi Image Lightbox
var $lightboxSingle = $('.js-lightbox-single');
if ($lightboxSingle.length) {
	$lightboxSingle.magnificPopup({
		delegate: '.js-lightbox-image',
		type: 'image'
	});
}

var $lightboxGallery = $('.js-lightbox-gallery');
if ($lightboxGallery.length) {
	$lightboxGallery.each(function() {
		$(this).magnificPopup({
			delegate: '.js-lightbox-image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			}
		});
	});
}

$('.js-lightbox-gallery-trigger').on('click', function (event) {
	event.preventDefault();
	var gallery = $(this).attr('href');

	$(gallery).magnificPopup({
		delegate: 'a',
		type: 'image',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1]
		}
	}).magnificPopup('open');
});
