import $ from 'jquery';
import * as util from 'Util/core';

var selectors = {
	yes: '.js-confirm-yes',
	no: '.js-confirm-no',
	close: '.js-confirm-close'
};

var Confirm = {
	check: function ($el, yes, no) {
		// Shows an element, and binds callbacks to "yes" and "no" buttons

		$el.show();
		$el.one('click keydown', selectors.yes, Confirm._buttonClick(yes, $el));
		$el.one('click keydown', selectors.no, Confirm._buttonClick(no, $el));
		$el.one('click keydown', selectors.close, Confirm._buttonClick($.noop, $el));
	},

	_buttonClick: function (callback, $el) {
		return util.activate(function (e) {
			e.preventDefault();
			$el.hide();

			callback.apply(this, arguments);
		});
	}
};

export { Confirm };
