/* app/ui/book/booknow */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import 'Lib/scrollspy';
import { templayed } from 'Lib/templayed';

var $BookNow = $('.js-book-now');
var bookNowRedirectFlag;

var $BookNowContent = $('.js-book-now-content');
var $TourSelectBtn = $('.js-tour-select-btn');
var $CheckAvailBtn = $('.js-check-avail-btn');
var $FindExperienceBtn = $('.js-destination-finder-btn');
var $DatePicker = $BookNowContent.find('.js-booknow-datepicker');

var $BookFlyOutBtn = $(".c-book-now-btn");
var $BookFlyOut = $(".c-book-now__flyout");
var $BookFlyOutCloseBtn = $(".flyout__close_btn");
var $Destination = $('.c-book-now-product-avail .js-departure');
var $Experience = $('.c-book-now-product-avail .js-tour-type');
var $ExpList = $('.c-book-now-product-avail .exp_list');

var BookNow = {
	init: function () {
		BookNow._bindEvents();

		$('input[type="number"]').on("keypress keyup blur", function (e) {
			$(this).val($(this).val().replace(/[^0-9\.]/g, ''));
		});
	},

	_bindEvents: function () {

		$BookFlyOutBtn.on("click", function (e) {
			e.preventDefault();
			$BookFlyOut.addClass("is-expanded");
			$Destination.change();
		});

		$BookFlyOutCloseBtn.on("click", function (e) {
			e.preventDefault();
			$BookFlyOut.removeClass("is-expanded");
		});

		$TourSelectBtn.on('click', BookNow._tourSelectClick);
		$CheckAvailBtn.on('click', BookNow._checkAvailClick);
		$FindExperienceBtn.on('click', BookNow.__findExperienceClick);
		$BookNowContent.on('change', '.js-departure', BookNow._TourTypeCheck);

		$Destination.on('change', BookNow.__findExperience);
	},

	_bookNowClick: function () {
		if (bookNowRedirectFlag) {
			var url = $(this).attr('href');
			if (url) {
				window.location.href = url;
			}
		}
		else {
			var $BookNowContent = $(this).closest('.js-book-now-content');
			//manuly change the height of book now to keep the animation and prevent it cover main content at the same time
			if ($BookNow.hasClass('is-expanded')) {
				$BookNow.removeClass('is-expanded');
				setTimeout(function () {
					$BookNowContent.css('height', 160);
				}, 1000);
			}
			else {
				$BookNowContent.css('height', 1000);
				$BookNow.addClass('is-expanded');
			}
		}
	},

	//for booking add and minus widget
	_guestNumUpdate: function ($item, action) {
		var $guestNum = $item.closest('.js-booking-widget-calc').find('.js-booking-guest-num');
		//var $plusBtn = $item.find('.js-booking-plus');

		if ($guestNum.length) {
			var currentNum = parseInt($guestNum.text(), 10);
			if (action === 'plus' && currentNum < 25) {
				currentNum++;
			} else if (action === 'minus' && currentNum > 1) {
				currentNum--;
				//$plusBtn.removeClass('is-disabled');
			}
			//if (currentNum === 25) {
			//	$plusBtn.addClass('is-disabled');
			//}
			$guestNum.text(currentNum);
		}
	},

	// find selected destination's experiences
	__findExperience: function (e) {
		var destination = $(this).find('option:selected').data('name').trim();
		var selectedDestination = destination.replace(/ /g, "-").toLowerCase();
		var experience = $ExpList.find('li');

		// remove all option except defaul value
		$Experience.find("option").not("[value='']").remove();

		experience.each(function (index) {
			var expurl = $(this).data('expurl');

			if (expurl) {
				var arr = expurl.split('/');
				var OptionValue = $(this).data('value');
				var OptionDestination = $(this).data('destination');
				var OptionText = $(this).text();

				// remove experience value
				$Experience.val("");

				if (arr.indexOf(selectedDestination) !== -1) {
					// create option
					var option = '<option value="' + OptionValue + '" data-destination="' + OptionDestination + '" data-expurl="' + expurl + '" >' + OptionText + '</option>';
					// append to experience select box
					$Experience.append(option);

				}
			}

		});

	},

	_tourSelectClick: function (event) {
		var $Target = $(event.target);

		if ($Target.attr('data-redirect') === 'true') {
			window.location.href = $Target.attr('href') + '?jumptoform=y';
		}

		var $Tabbery = $Target.closest('.js-tabbery-customized');
		var href = $Target.attr('href');

		if (!$Tabbery.length) {
			return;
		}

		var $SelectedTab = $Tabbery.find(href);

		if ($Target.hasClass('is-selected')) {
			$Target.removeClass('is-selected');
			$SelectedTab.removeClass('is-visible');
		} else {
			$Tabbery.find('.js-tour-select-btn').removeClass('is-selected');
			$Tabbery.find('.js-tabbery-content').removeClass('is-visible');
			$Target.addClass('is-selected');
			$SelectedTab.addClass('is-visible');

			BookNow._TourTypeCheck();
		}
	},

	_checkAvailClick: function (event) {
		var $Target = $(event.target);
		var $Tabbery = $Target.closest('.js-tour-tab');
		var date = $Tabbery.find('.js-booknow-datepicker').val();
		var anchor = $Tabbery.find('.js-departure').val();
		var promo = $Tabbery.find('.js-booknow-promo').val();
		var groupSize = $Tabbery.find('.js-booking-group-size').val();
		var tourType = '';
		var appendUrl = '?checkavail=y&date=' + date + '&groupSize=' + groupSize + '&PROMO=' + promo + '&productId=' + anchor;

		var experience = $Tabbery.find('.js-tour-type').find('option:selected').attr('data-expurl');
		var desturl = experience;

		if ($Tabbery.find('.js-tour-type').length) {
			tourType = $Tabbery.find('.js-tour-type').val();
			appendUrl = appendUrl + '&tourType=' + tourType;
		}
		if (desturl) {
			window.location.href = desturl + appendUrl + '#' + anchor;
		}
	},

	__findExperienceClick: function (event) {
		var $Target = $(event.target);
		var $Tabbery = $Target.closest('.js-experience-tab');
		var baseUrl = $Target.data('base-url');
		var destinationFilterName = $Tabbery.find('.js-destination-select').data('filter-name');
		var destination = $Tabbery.find('.js-destination-select').val();
		var durationFilterName = $Tabbery.find('.js-duration-select').data('filter-name');
		var duration = $Tabbery.find('.js-duration-select').val();
		var appendUrl = '?' + destinationFilterName + '=' + destination + '&' + durationFilterName +'=' + duration;
		window.location.href = baseUrl + appendUrl;
	},

	_TourTypeCheck: function () {
		var $activeTab = $('.js-tour-tab').filter(':visible');
		var $DepartFrom = $activeTab.find('.js-departure');
		if ($DepartFrom.length) {
			var $selectOption = $DepartFrom.find('option:selected');
			if (!$selectOption.length) {
				//if no find, should be the hidden input one
				$selectOption = $DepartFrom;
			}
			var tourTypeData = $selectOption.attr('data-tour-types');
			if (tourTypeData) {
				var tourTypes = JSON.parse(tourTypeData);
				var $tourTypeInsert = $activeTab.find('.js-tour-type-insert');

				if (tourTypes.length > 0) {
					//console.log(tourTypes);
					var templateVars = {};
					templateVars.Options = tourTypes;

					if (!$tourTypeInsert.length) {
						return;
					}
					var insertTemplate = $('#js-tour-type-insert').html();

					var template = insertTemplate.replace(/(\r\n|\n|\r)/gm, '');
					var compiledTemplate = templayed(template)(templateVars);
					$tourTypeInsert.html(compiledTemplate);
				} else {
					$tourTypeInsert.empty();
				}
			}
		}
	},

	_initMediaQueries: function () {
		MediaQueries.init();

		var setShowDateWindow = function (bp) {
			return function () {
				if (bp === 'small') {
					bookNowRedirectFlag = false;
					$DatePicker.on('click', function (event) {
						var $target = $(event.target);
						var scrollHeight = $target.offset().top;
						$BookNowContent.animate({ scrollTop: scrollHeight }, 300);
					});
				}
				else if (bp === 'medium') {
					bookNowRedirectFlag = true;
				}
				else if (bp === 'large') {
					bookNowRedirectFlag = true;
				}
				else if (bp === 'extra-large') {
					bookNowRedirectFlag = false;
				}
			};

		};

		MediaQueries.register([
			{
				queries: MediaQueries.queries['booking--extra-large'],
				shouldDegrade: false,
				match: setShowDateWindow('extra-large')
			},
			{
				queries: MediaQueries.queries['booking--large'],
				shouldDegrade: false,
				match: setShowDateWindow('large')
			},
			{
				queries: MediaQueries.queries['booking--medium'],
				shouldDegrade: false,
				match: setShowDateWindow('medium')
			},
			{
				queries: MediaQueries.queries['booking--small'],
				shouldDegrade: false,
				match: setShowDateWindow('small')
			}
		]);
	}
};

export { BookNow };
